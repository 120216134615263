<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">企业档案</el-breadcrumb-item>
      <el-breadcrumb-item>企业画像</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-box">
      <div class="fl-c-b">
        <div class="title">浙江怀远电器有限公司</div>
        <div class="time">更新时间：2020-12-19 | 状态：正常</div>
      </div>
      <div class="session-title">整体评估</div>
      <div class="fl-c-b">
        <!-- <Chart class="chart-left" ref="chart"></Chart> -->
        <v-chart autoresize :options="option" class="chart-left" />
        <div class="prog-list">
          <div class="prog-item" v-for="(item, index) in progData" :key="index">
            <div class="loop-pie">
              <div class="loop-pie-line loop-pie-r">
                <div
                  class="loop-pie-c loop-pie-rm-red"
                  :class="'loop-pie-' + index"
                  :style="{ '-webkit-transform': 'rotate(' + loadPercentRight(item.value, total) + 'deg)' }"
                ></div>
              </div>
              <div class="loop-pie-line loop-pie-l">
                <div
                  class="loop-pie-c"
                  :class="'loop-pie-' + index"
                  :style="{ '-webkit-transform': 'rotate(' + loadPercentLeft(item.value, total) + 'deg)' }"
                ></div>
              </div>
            </div>
            <div class="prog-title">{{ item.lable }}</div>
            <div class="prog-number">{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div class="session-title">画像详情</div>
      <div class="clearfix">
        <div class="info-item fl" style="width: 370px; margin-right: 20px">
          <div class="info-header fl-c-b">
            <div class="info-title">企业工商登记信息</div>
            <img src="../../../assets/img/brainDecision/icon_xq.png" @click="show(data1)" class="info-icon" alt="" />
          </div>
          <div style="padding: 16px 40px">
            <div class="btn">基本信息</div>
            <div class="fl-c-b" style="margin-top: 16px; margin-right: 25px">
              <div>
                <div class="item-info">
                  <div>所属行业</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">发展前景</div>
                    <div class="good">良好</div>
                  </div>
                </div>
                <div class="item-info" style="margin: 20px 0">
                  <div>企业性质</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">发展前景</div>
                    <div class="decline">衰退</div>
                  </div>
                </div>
                <div class="item-info">
                  <div>注册资本</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">资本实力</div>
                    <div class="good">强</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="item-info-right">
                  <div>经营范围</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-z">发展前景</div>
                    <div class="moderate">平稳</div>
                  </div>
                </div>
                <div class="item-info-right" style="margin: 20px 0">
                  <div>企业规模</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-z">企业规模</div>
                    <div class="moderate">中等</div>
                  </div>
                </div>
                <div class="item-info-right">
                  <div>成立年限</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">行业经验</div>
                    <div class="decline">缺乏</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-item fl Financial-info" style="width: calc(100% - 390px)">
          <div class="info-header fl-c-b">
            <div class="info-title">企业财务信息</div>
            <img src="../../../assets/img/brainDecision/icon_xq.png" @click="show(data2)" class="info-icon" alt="" />
          </div>
          <div style="padding: 16px 22px">
            <div class="fl-c-b">
              <div class="btn">偿债能力</div>
              <div class="btn">营运能力</div>
              <div class="btn">盈利能力</div>
              <div class="btn" style="margin-right: 25px">成长能力</div>
            </div>
            <div class="fl-c-b" style="margin-top: 16px">
              <div>
                <div class="item-info">
                  <div>流动能力</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">偿债能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
                <div class="item-info" style="margin: 20px 0">
                  <div>速动比率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">偿债能力</div>
                    <div class="decline">弱</div>
                  </div>
                </div>
                <div class="item-info">
                  <div>资产负债率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">偿债能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="line"></div>
                <div class="line" style="margin: 20px 0"></div>
                <div class="line"></div>
              </div>
              <div>
                <div class="item-info">
                  <div>应收款项周转率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">营运能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
                <div class="item-info" style="margin: 20px 0">
                  <div>存贷周转率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">营运能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
                <div class="item-info">
                  <div>总资产周转率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">营运能力</div>
                    <div class="decline">弱</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="line"></div>
                <div class="line" style="margin: 20px 0"></div>
                <div class="line"></div>
              </div>
              <div>
                <div class="item-info">
                  <div>净资产收益率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">盈利能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
                <div class="item-info" style="margin: 20px 0">
                  <div>总资产收益率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">盈利能力</div>
                    <div class="good">强</div>
                  </div>
                </div>
                <div class="item-info">
                  <div>营业收入利润率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">盈利能力</div>
                    <div class="decline">弱</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="line"></div>
                <div class="line" style="margin: 20px 0"></div>
                <div class="line"></div>
              </div>
              <div>
                <div class="item-info-right">
                  <div>总资产增长率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">成长能力</div>
                    <div class="decline">弱</div>
                  </div>
                </div>
                <div class="item-info-right" style="margin-top: 40px">
                  <div>营业收入增长率</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">成长能力</div>
                    <div class="decline">弱</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix" style="margin: 20px 0">
        <div class="info-item fl" style="width: 370px; margin-right: 20px">
          <div class="info-header fl-c-b">
            <div class="info-title">企业银联流水信息</div>
            <img src="../../../assets/img/brainDecision/icon_xq.png" @click="show(data3)" class="info-icon" alt="" />
          </div>
          <div style="padding: 16px 40px 26px">
            <div class="btn">交易流水</div>
            <div class="fl-c-b" style="margin-top: 16px">
              <div>
                <div class="item-info">
                  <div>月交易金额</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">交易规模</div>
                    <div class="good">大额</div>
                  </div>
                </div>
                <div class="item-info" style="margin: 20px 0">
                  <div>月清算金额</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r">交易规模</div>
                    <div class="decline">小额</div>
                  </div>
                </div>
                <div class="item-info">
                  <div>月交易笔数</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-g">交易频率</div>
                    <div class="good">活跃</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="item-info-right">
                  <div>月交易天数</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-z">交易频率</div>
                    <div class="moderate">适中</div>
                  </div>
                </div>
                <div class="item-info-right" style="margin-top: 40px">
                  <div>月交易客户数量</div>
                  <div class="bq fl-c" style="margin-top: 12px">
                    <div class="bq-r2">客户保有量</div>
                    <div class="decline">少</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-item fl" style="width: calc(100% - 390px)">
          <div class="info-header fl-c-b">
            <div class="info-title">企业风险信息</div>
            <img src="../../../assets/img/brainDecision/icon_xq.png" @click="show(data4)" class="info-icon" alt="" />
          </div>
          <div style="padding: 6px 24px 0">
            <div class="risk-item" style="display: flex">
              <div class="btn">司法信息</div>
              <div class="fl-c risk-info">
                <div class="risk-info-item">
                  <div>历史诉讼记录数</div>
                  <div class="red-round round">红色</div>
                </div>
                <div class="risk-info-item">
                  <div>当前司法诉讼数</div>
                  <div class="green-round round">绿色</div>
                </div>
                <div class="risk-info-item">
                  <div>是否存在司法拍卖</div>
                  <div class="blue-round round">蓝色</div>
                </div>
                <div class="risk-info-item">
                  <div>是否是失信被执行人</div>
                  <div class="yellow-round round">黄色</div>
                </div>
              </div>
            </div>
            <div class="risk-item" style="display: flex">
              <div class="btn">税务信息</div>
              <div class="fl-c risk-info">
                <div class="risk-info-item">
                  <div>纳税人类型</div>
                  <div class="orange-round round">橙色</div>
                </div>
                <div class="risk-info-item">
                  <div>纳税信用等级</div>
                  <div class="red-round round">红色</div>
                </div>
                <div class="risk-info-item">
                  <div>欠税次数</div>
                  <div class="blue-round round">蓝色</div>
                </div>
                <div class="risk-info-item">
                  <div>欠税税种</div>
                  <div class="blue-round round">蓝色</div>
                </div>
                <div class="risk-info-item" style="margin-top: 16px">
                  <div>历史欠税总金额</div>
                  <div class="green-round round">正常</div>
                </div>
                <div class="risk-info-item" style="margin-top: 16px">
                  <div>最新一期欠税总金额</div>
                  <div class="yellow-round round">黄色</div>
                </div>
              </div>
            </div>
            <div class="risk-item" style="display: flex">
              <div class="btn">经营状态</div>
              <div class="fl-c-b risk-info">
                <div class="risk-info-item">
                  <div>是否标记为经营异常</div>
                  <div class="orange-round round">橙色</div>
                </div>
                <div class="risk-info-item" style="border-right: 1px solid #eee">
                  <div>是否曾被标记为经营异常</div>
                  <div class="red-round round">红色</div>
                </div>
                <div style="width: calc(25% - 19px); margin-left: 19px">
                  <div class="btn">变更信息</div>
                </div>
                <div class="risk-info-item">
                  <div>基本信息变更次数</div>
                  <div class="blue-round round">蓝色</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-item">
        <div class="info-header fl-c-b">
          <div class="info-title">企业创新信息</div>
          <img src="../../../assets/img/brainDecision/icon_xq.png" @click="show(data5)" class="info-icon" alt="" />
        </div>
        <div style="padding: 16px 0 25px 20px; display: flex">
          <div style="width: 339px; margin-right: 55px">
            <div class="btn">创新基础</div>
            <div class="fl-c-b">
              <div class="innovation-top-item">
                <div class="innovation-text">3年科技研究经费平均增长率</div>
                <div class="bq fl-c">
                  <div class="bq-g2">创新支持力度</div>
                  <div class="good">强</div>
                </div>
              </div>
              <div class="innovation-top-item-r">
                <div class="innovation-text">科技研究人员平均研究经费</div>
                <div class="bq fl-c">
                  <div class="bq-r2">创新支持力度</div>
                  <div class="decline">弱</div>
                </div>
              </div>
            </div>
            <div style="margin: 20px 0 30px">
              <div class="innovation-text">科技研究经费支出占主管业务收入比重</div>
              <div class="bq fl-c">
                <div class="bq-z2">创新支持力度</div>
                <div class="moderate">中等</div>
              </div>
            </div>
            <div class="btn">创新活动</div>
            <div style="border-right: 1px solid #eee">
              <div class="innovation-text">企业拥有科技研究项目数</div>
              <div class="bq fl-c">
                <div class="bq-z2">创新积极性</div>
                <div class="moderate">中等</div>
              </div>
            </div>
            <div style="border-right: 1px solid #eee">
              <div class="innovation-text">企业拥有新产品开发项目数</div>
              <div class="bq fl-c">
                <div class="bq-z2">创新积极性</div>
                <div class="moderate">中等</div>
              </div>
            </div>
            <div style="border-right: 1px solid #eee">
              <div class="innovation-text">科研合作支出占科技活动总额比例</div>
              <div class="bq fl-c">
                <div class="bq-r2">创新积极性</div>
                <div class="decline">弱</div>
              </div>
            </div>
          </div>
          <div style="width: 255px">
            <div class="btn">创新能力</div>
            <div class="" style="margin-top: 18px; margin-bottom: 38px">
              <div class="innovation-text">技术引进支出占主营业务收入比例</div>
              <div class="bq fl-c">
                <div class="bq-g">创新能力</div>
                <div class="good">强</div>
              </div>
            </div>

            <div style="margin-bottom: 28px">
              <div class="innovation-text">企业对国外技术的依存度</div>
              <div class="bq fl-c" style="margin-top: 12px">
                <div class="bq-r">创新能力</div>
                <div class="decline">弱</div>
              </div>
            </div>
            <div class="btn">创新绩效</div>
            <div>
              <div class="innovation-text">是否国家认定创新型企业</div>
              <div class="bq fl-c">
                <div class="bq-g3">创新活动转化率</div>
                <div class="good">高</div>
              </div>
            </div>
            <div>
              <div class="innovation-text">企业拥有专利数</div>
              <div class="bq fl-c">
                <div class="bq-r3">创新活动转化率</div>
                <div class="decline">低</div>
              </div>
            </div>
            <div>
              <div class="innovation-text">企业拥有发明专利数</div>
              <div class="bq fl-c">
                <div class="bq-r3">创新活动转化率</div>
                <div class="decline">低</div>
              </div>
            </div>
          </div>
          <div style="flex: 1">
            <div style="margin-top: 60px" class="innovation-r-i">
              <div class="innovation-text">科技研究人员占就业人员比重</div>
              <div class="bq fl-c">
                <div class="bq-g">创新能力</div>
                <div class="good">强</div>
              </div>
            </div>
            <div style="margin-top: 36px" class="innovation-r-i">
              <div class="innovation-text">就业人员中博士和硕士毕业生所占比重</div>
              <div class="bq fl-c">
                <div class="bq-r">创新能力</div>
                <div class="decline">弱</div>
              </div>
            </div>
            <div style="margin-top: 90px" class="innovation-r-i">
              <div class="innovation-text">每百万科研经费产生发明专利数量</div>
              <div class="bq fl-c">
                <div class="bq-z3">创新活动转化率</div>
                <div class="moderate">中等</div>
              </div>
            </div>
            <div style="margin-top: 48px" class="innovation-r-i">
              <div class="innovation-text">新产品销售收入占主要业务收入比例</div>
              <div class="bq fl-c">
                <div class="bq-z3">创新活动转化率</div>
                <div class="moderate">中等</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="企业创新信息数据" :visible.sync="dialogVisible" width="754px" center>
      <div class="clearfix">
        <div class="dailog-item fl" v-for="(item, index) in list" :key="index">
          <div class="dailog-text">{{ item }}</div>
          <div class="dailog-value">50%</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'demandDetails',
  data() {
    return {
      progData: [
        { lable: '基础能力', value: 90 },
        { lable: '信用风险', value: 270 },
        { lable: '创新发展', value: 270 },
        { lable: '企业成长', value: 90 },
        { lable: '项目效益', value: 80 },
        { lable: '管理效益', value: 25 },
      ],
      total: 0,
      dialogVisible: false,
      list: [],
      data1: ['所属行业', '经营范围', '企业性质', '企业规模', '注册资本', '成立年限'],
      data2: [
        '流动能力',
        '应收款项周转率',
        '净资产收益率',
        '总资产增长率',
        '速动比率',
        '存贷周转率',
        '总资产收益率',
        '资产负债率',
        '总资产周转率',
        '营业收入利润率',
        '营业收入增长率',
      ],
      data3: ['月交易金额', '月清算金额', '月交易笔数', '月交易天数', '月交易客户数量'],
      data4: [
        '历史诉讼记录数',
        '当前司法诉讼数',
        '是否存在司法拍卖',
        '是否是失信被执行人',
        '纳税人类型',
        '纳税信用等级',
        '欠税次数',
        '欠税税种',
        '历史欠税总金额',
        '最新一期欠税总金额',
        '是否标记为经营异常',
        '是否曾被标记为经营异常',
        '基本信息变更次数',
      ],
      data5: [
        '3年科技研究经费平均增长率',
        '科技研究人员平均研究经费',
        '科技研究经费支出占主管业务收入比重',
        '企业拥有科技研究项目数',
        '企业拥有新产品开发项目数',
        '科研合作支出占科技活动总额比例',
        '技术引进支出占主营业务收入比例',
        '科技研究人员占就业人员比重',
        '企业对国外技术的依存度',
        '就业人员中博士和硕士毕业生所占比重',
        '是否国家认定创新型企业',
        '企业拥有专利数',
        '企业拥有发明专利数',
        '每百万科研经费产生发明专利数量',
        '新产品销售收入占主要业务收入比例',
      ],
    };
  },
  methods: {
    loadPercentLeft(x, y) {
      var rotate = (x / y) * 360;
      if (rotate <= 180) {
        return -45 - rotate;
      } else {
        return -225;
      }
    },
    loadPercentRight(x, y) {
      var rotate = (x / y) * 360;
      console.log(rotate);
      if (rotate > 180) {
        return 135 - rotate;
      } else {
        return -45;
      }
    },
    show(data) {
      this.list = data;
      this.dialogVisible = true;
    },
  },

  mounted() {},
  created() {
    this.total = this.progData.reduce(function (a, b) {
      return a + b.value;
    }, 0);
  },
  computed: {
    option() {
      return {
        tooltip: {
          show: true,
          trigger: 'item',
        },
        backgroundColor: '#FAFAFA',
        // legend: {
        //   type: 'scroll',
        //   bottom: 10,
        //   data: (function () {
        //     var list = [];
        //     for (var i = 1; i <= 28; i++) {
        //       list.push(i + 2000 + '');
        //     }
        //     return list;
        //   })(),
        // },
        visualMap: {
          top: 'middle',
          right: 10,
          color: ['#FF0000', '#FFDD00'],
          calculable: true,
        },
        radar: {
          indicator: [
            {
              text: '基础能力',
              max: 200,
            },
            {
              text: '信用风险',
              max: 200,
            },
            {
              text: '创新发展',
              max: 200,
            },
            {
              text: '企业成长',
              max: 200,
            },
            {
              text: '项目效益',
              max: 200,
            },
            {
              text: '管理效率',
              max: 200,
            },
          ],
        },
        series: [
          {
            name: '整体评估',
            type: 'radar',
            symbol: 'none',
            lineStyle: {
              width: 1,
            },
            areaStyle: {
              normal: {
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#FFDD00', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FF0000', // 0% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [
              {
                value: [160, 160, 40, 120, 80, 160],
              },
            ],
          },
        ],
      };
    },
  },
};
</script>


<style scoped>
.content-box {
  background-color: #fff;
  padding: 27px 20px 17px;
}

.title {
  font-size: 20px;
  color: #333333;
  line-height: 26px;
  font-weight: 600;
}

.time {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.session-title {
  margin-top: 26px;
  position: relative;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  padding-bottom: 15px;
  padding-left: 6px;
  font-weight: 600;
  margin-bottom: 16px;
}

.session-title::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  width: 2px;
  height: 14px;
  background: #0e459c;
}

.chart-left {
  width: 50%;
  height: 380px;
  border: 1px solid #eeeeee;
}

.prog-item {
  position: relative;
  padding: 40px 40px 20px;
  width: calc(100% / 3);
  box-sizing: border-box;
}

.prog-title {
  font-size: 12px;
  color: #666666;
  text-align: center;
  line-height: 12px;
  margin-top: 20px;
}

.prog-number {
  font-size: 20px;
  color: #333333;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%);
}

.prog-list {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.loop-pie {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.loop-pie-line {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  overflow: hidden;
}

.loop-pie-l {
  top: 0px;
  left: 0px;
}

.loop-pie-r {
  top: 0px;
  -webkit-transform: rotate(180deg);
  right: 0px;
}

.loop-pie-c {
  width: 200%;
  height: 100%;
  border: 8px solid transparent;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  -webkit-transform: rotate(-45deg);
}

.loop-pie-0,
.loop-pie-3 {
  border-top: 8px solid #fde4e8;
  border-left: 8px solid #fde4e8;
  border-bottom: 8px solid #ef4864;
  border-right: 8px solid #ef4864;
}

.loop-pie-1,
.loop-pie-4 {
  border-top: 8px solid #e0f6e7;
  border-left: 8px solid #e0f6e7;
  border-bottom: 8px solid #2fc25b;
  border-right: 8px solid #2fc25b;
}

.loop-pie-2,
.loop-pie-5 {
  border-top: 8px solid #e0f0ff;
  border-left: 8px solid #e0f0ff;
  border-bottom: 8px solid #3196fa;
  border-right: 8px solid #3196fa;
}

.info-item {
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(170, 183, 210, 0.46);
}

.info-header {
  height: 46px;
  background: #3863a0;
  padding: 0 20px;
}

.info-title {
  font-size: 16px;
  color: #ffffff;
  line-height: 21px;
  font-weight: 600;
}

.info-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.item-info {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  padding-right: 48px;
  border-right: 1px solid #eee;
}
.line {
  height: 40px;
  width: 1px;
  background-color: #eee;
}
.Financial-info .item-info{
  padding-right: 0;
  border:none
}

.item-info-right {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}

.bq-g {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_g1.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  width: 67px;
  padding-left: 2px;
}

.bq-r {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_d1.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  width: 67px;
  padding-left: 2px;
}

.bq-r2 {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_d2.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  padding-left: 2px;
  width: 99px;
}

.bq-z2 {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_z2.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  padding-left: 2px;
  width: 99px;
}

.bq-g2 {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_g2.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  padding-left: 2px;
  width: 99px;
}

.bq-g3 {
  font-size: 11px;
  color: #ffffff;
  line-height: 15px;
  background: url(../../../assets/img/brainDecision/img_bq_g3.png) center no-repeat;
  background-size: 100%;
  padding-left: 2px;
  height: 15px;
  width: 109px;
}

.bq-r3 {
  font-size: 11px;
  color: #ffffff;
  line-height: 15px;
  background: url(../../../assets/img/brainDecision/img_bq_d3.png) center no-repeat;
  background-size: 100%;
  padding-left: 2px;
  height: 15px;
  width: 109px;
}

.bq-z3 {
  font-size: 11px;
  color: #ffffff;
  line-height: 15px;
  background: url(../../../assets/img/brainDecision/img_bq_z3.png) center no-repeat;
  background-size: 100%;
  padding-left: 2px;
  height: 15px;
  width: 109px;
}

.bq-z {
  font-size: 11px;
  color: #ffffff;
  line-height: 14px;
  background: url(../../../assets/img/brainDecision/img_bq_z1.png) center no-repeat;
  background-size: 100%;
  height: 14px;
  width: 67px;
  padding-left: 2px;
}

.good {
  font-size: 12px;
  color: #00e790;
  line-height: 12px;
}

.decline {
  font-size: 12px;
  color: #ef5350;
  line-height: 12px;
}

.moderate {
  font-size: 12px;
  color: #409eff;
  line-height: 12px;
}

.risk-item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.risk-item:nth-last-of-type(1) {
  border-bottom: none;
}

.risk-info {
  flex: 1;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-left: 20px;
  flex-wrap: wrap;
}

.risk-info-item {
  width: 25%;
}

.round {
  margin-top: 6px;
  position: relative;
  padding-left: 18px;
  font-size: 12px;
  line-height: 20px;
}

.round::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 0;
  border-radius: 50%;
}

.red-round {
  color: #ef4864;
}

.red-round::after {
  background: #ef4864;
}

.green-round {
  color: #2fc25b;
}

.green-round::after {
  background: #2fc25b;
}

.blue-round {
  color: #409eff;
}

.blue-round::after {
  background: #409eff;
}

.yellow-round {
  color: #f7b500;
}

.yellow-round::after {
  background: #f7b500;
}

.orange-round {
  color: #fa6400;
}

.orange-round::after {
  background: #fa6400;
}

.btn {
  height: 26px;
  background-image: linear-gradient(270deg, #92bfea 0%, #5889cf 100%);
  width: 76px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  color: #ffffff;
}

.innovation-top-item {
  position: relative;
  width: 138px;
  padding-right: 30px;
  margin-right: 30px;
}

.innovation-top-item::after {
  content: '';
  position: absolute;
  right: 0;
  top: 27px;
  width: 1px;
  height: 42px;
  background-color: #eee;
}

.innovation-top-item-r {
  padding-right: 55px;
  width: 169px;
  position: relative;
}

.innovation-top-item-r::after {
  content: '';
  position: absolute;
  right: 0;
  top: 16px;
  width: 1px;
  height: 42px;
  background-color: #eee;
}

.innovation-text {
  font-size: 14px;
  color: #333333;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.innovation-r-i {
  padding-left: 41px;
  position: relative;
}

.innovation-r-i::after {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 42px;
  background-color: #eee;
}

.dailog-item {
  width: 112px;
  margin-bottom: 26px;
  margin-right: 82px;
  position: relative;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.dailog-value {
  margin-top: 12px;
  font-size: 18px;
  color: #0e459c;
  line-height: 20px;
  font-weight: 600;
}

.dailog-item::after {
  content: '';
  position: absolute;
  top: 4px;
  right: -42px;
  width: 1px;
  height: 46px;
  background-color: #ddd;
}

.dailog-item:nth-of-type(4n + 2) {
  width: 128px;
  margin-right: 66px;
}

.dailog-item:nth-of-type(4n + 2)::after {
  right: -26px;
}

.dailog-item:nth-of-type(4n) {
  margin-right: 0;
}

.dailog-item:nth-of-type(4n)::after {
  width: 0;
}
</style>